import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  CssBaseline,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Container,
  createTheme,
  ThemeProvider,
  Card,
  CardMedia,
  Box,
  Chip,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import InfoRounded from "@mui/icons-material/InfoRounded";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Sidebar from "./Sidebar";
import useMediaQuery from "@mui/material/useMediaQuery";

const theme = createTheme({
  palette: {
    primary: {
      main: "#000000",
    },
    secondary: {
      main: "#FFFFFF",
    },
  },
});



function Posts() {
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [posts, setPosts] = useState([]);
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const email = localStorage.getItem("email");

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await axios.get(
          `https://api.thenarad.in/getallposts/${email}`
        );
        setPosts(response.data.posts.reverse());
      } catch (error) {
        console.error("Error fetching posts:", error);
      }
    };

    fetchPosts();
  }, [email]);

  const handleSidebarToggle = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  return (
    <ThemeProvider theme={theme}>
      <div>
        <CssBaseline />
        <AppBar position="fixed">
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open sidebar"
              onClick={handleSidebarToggle}
              sx={{ mr: 2 }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap>
              SOCIAL MEDIA SCHEDULER
            </Typography>
          </Toolbar>
        </AppBar>
        <Sidebar isOpen={isSidebarOpen} onClose={handleSidebarToggle} />
        <Container
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-start",
            height: "100vh",
            marginTop: "64px",
          }}
        >
          <div>
            <h1>Posts</h1>
            {posts.length === 0 ? (
              <Typography
                variant="h6"
                fontWeight="bold"
                color="red"
                gutterBottom
              >
                NO POSTS SCHEDULED
              </Typography>
            ) : (
              posts.map((post) => (
                <Card
                  key={post._id}
                  variant="outlined"
                  sx={{
                    p: 2,
                    display: "flex",
                    flexWrap: "wrap",
                    zIndex: 1,
                    mb: 2,
                  }}
                >
                  {!isMobile && ( // Render image only if not on mobile
                    <CardMedia
                      component="img"
                      width="100"
                      height="100"
                      alt="Post Image"
                      src={
                        post.postresolved
                          ? "https://cdn-icons-png.flaticon.com/512/665/665939.png"
                          : `https://api.thenarad.in/images/${post.mediaid}`
                      }
                      sx={{
                        borderRadius: "6px",
                        width: { xs: "100%", sm: 100 },
                      }}
                    />
                  )}
                  <Box sx={{ alignSelf: "center", ml: isMobile ? 0 : 2 }}>
                    {" "}
                    {/* Adjust margin left for mobile */}
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      fontWeight="regular"
                    >
                      {post.postdescription.length > 50
                        ? `${post.postdescription.slice(0, 50)}...`
                        : post.postdescription}
                    </Typography>
                    <Typography fontWeight="bold" noWrap gutterBottom>
                      Scheduled Time:{" "}
                      {new Date(post.scheduledtime).toLocaleString()}
                    </Typography>
                    <Chip
                      size="small"
                      variant="outlined"
                      icon={
                        post.postresolved ? (
                          <CheckCircleIcon />
                        ) : (
                          <InfoRounded />
                        )
                      }
                      label={post.postresolved ? "POSTED" : "SCHEDULED"}
                      sx={{
                        ".MuiChip-icon": {
                          fontSize: 16,
                          ml: "4px",
                          color: post.postresolved ? "success.500" : "inherit",
                        },
                        bgcolor: post.postresolved ? "success.50" : "default",
                        borderColor: post.postresolved
                          ? "success.100"
                          : "default",
                        color: post.postresolved ? "success.900" : "default",
                      }}
                    />
                  </Box>
                </Card>
              ))
            )}
          </div>
        </Container>
      </div>
    </ThemeProvider>
  );
}

export default Posts;
