import React from 'react';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import { Home, CalendarToday, Settings,AccountBalanceWallet,History,YouTube } from '@mui/icons-material';


const Sidebar = ({ isOpen, onClose }) => {
  return (
    <Drawer open={isOpen} onClose={onClose}>
      <List>
        <Typography variant="h5" align="center" sx={{ margin: 3 }}>
          SM SCHEDULER
        </Typography>
        <ListItem component={Link} to="/" button>
          <ListItemIcon>
            <Home />
          </ListItemIcon>
          <ListItemText primary="Home" />
        </ListItem>
        <ListItem component={Link} to="/schedule" button>
          <ListItemIcon>
            <CalendarToday />
          </ListItemIcon>
          <ListItemText primary="Schedule" />
        </ListItem>
        <ListItem component={Link} to="/wallet" button>
      <ListItemIcon>
        <AccountBalanceWallet />
      </ListItemIcon>
      <ListItemText primary="Wallet" /> 
    </ListItem>
    <ListItem component={Link} to="/history" button>
          <ListItemIcon>
            <History/>
          </ListItemIcon>
          <ListItemText primary="Posts" />
        </ListItem>
        <ListItem component={Link} to="/ytstuff" button>
          <ListItemIcon>
            <YouTube/>
          </ListItemIcon>
          <ListItemText primary="YT features" />
        </ListItem>
        <ListItem component={Link} to="/settings" button>
          <ListItemIcon>
            <Settings />
          </ListItemIcon>
          <ListItemText primary="Settings" />
        </ListItem>
    
      </List>
    </Drawer>
  );
};

export default Sidebar;
