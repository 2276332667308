import React from 'react';
import { Typography, Grid, Paper, Link } from '@mui/material';

const Policies = () => {
  return (
    <Grid container justifyContent="center" alignItems="center" style={{ minHeight: '100vh' }}>
      <Grid item xs={12} md={8} lg={6}>
        <Paper elevation={3} style={{ padding: '2rem' }}>
          <Typography variant="h4" gutterBottom>
            Privacy Policy:
          </Typography>
          <Typography variant="body1" gutterBottom>
            <strong>At TheNarad,</strong> we prioritize the privacy and security of our users' information. This Privacy Policy outlines how we collect, use, disclose, and safeguard your personal information when you use our Social Media Management Tool.
          </Typography>
          <ol>
            <li>
              <Typography variant="body1">
                <strong>Information We Collect:</strong>
                <ul>
                  <li>
                    Personal Information: We may collect personal information such as your name, email address, billing address, and payment information when you register an account with us or make a purchase.
                  </li>
                  <li>
                    Usage Information: We may collect information about how you interact with our platform, including your device information, IP address, browser type, and usage patterns.
                  </li>
                </ul>
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                <strong>Use of Information:</strong>
                <ul>
                  <li>
                    We use the collected information to provide and improve our services, process transactions, communicate with you, and personalize your experience.
                  </li>
                  <li>
                    Your personal information will not be shared with third parties except as necessary to provide our services or comply with legal obligations.
                  </li>
                </ul>
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                <strong>Security:</strong>
                <ul>
                  <li>
                    We implement industry-standard security measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction.
                  </li>
                  <li>
                    However, please note that no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.
                  </li>
                </ul>
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                <strong>Cookies:</strong>
                <ul>
                  <li>
                    We may use cookies and similar tracking technologies to enhance your experience and analyze usage patterns. You can configure your browser to refuse all cookies or to indicate when a cookie is being sent.
                  </li>
                </ul>
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                <strong>Changes to Privacy Policy:</strong>
                <ul>
                  <li>
                    We reserve the right to update this Privacy Policy at any time. Any changes will be posted on this page, and your continued use of our services constitutes acceptance of the updated policy.
                  </li>
                </ul>
              </Typography>
            </li>
          </ol>

          <Typography variant="h4" gutterBottom style={{ marginTop: '2rem' }}>
            Refund Policy:
          </Typography>
          <ol>
            <li>
              <Typography variant="body1">
                <strong>Subscription Fees:</strong>
                <ul>
                  <li>
                    Subscription fees for TheNarad are non-refundable. Once a payment is made for a subscription plan, no refunds will be provided for any unused portion of the subscription period.
                  </li>
                </ul>
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                <strong>Cancelation of Services:</strong>
                <ul>
                  <li>
                    You may cancel your subscription at any time, but no refunds will be issued for the remaining period of the subscription.
                  </li>
                  <li>
                    Upon cancelation, your access to our services will continue until the end of the current billing cycle.
                  </li>
                </ul>
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                <strong>Exceptional Circumstances:</strong>
                <ul>
                  <li>
                    Refunds may be provided at our discretion in exceptional circumstances, such as technical issues preventing access to our services for an extended period.
                  </li>
                </ul>
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                <strong>Contact Us:</strong>
                <ul>
                  <li>
                    If you have any questions or concerns about our Privacy Policy or Refund Policy, please contact us at <Link href="mailto:support@thenarad.in">support@thenarad.in</Link>.
                  </li>
                </ul>
              </Typography>
            </li>
          </ol>

          <Typography variant="body1" gutterBottom style={{ marginTop: '2rem' }}>
            By using TheNarad's services, you acknowledge that you have read and agree to our <Link href="#" color="secondary">Privacy Policy</Link> and <Link href="#" color="secondary">Refund Policy</Link>.
          </Typography>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default Policies;
