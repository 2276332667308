import React, { useState, useEffect } from "react";
import {
  CssBaseline,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Container,
  createTheme,
  ThemeProvider,
  Grid,
  Button,
  TextField,
  Paper,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import InsertEmoticonIcon from "@mui/icons-material/InsertEmoticon";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import InsertVideoIcon from "@mui/icons-material/AttachFile";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import EmojiPicker from "emoji-picker-react"; // Assuming you have a third-party emoji picker component
import Sidebar from "./Sidebar";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import XIcon from "@mui/icons-material/X";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import GestureIcon from '@mui/icons-material/Gesture';

const theme = createTheme({
  palette: {
    primary: {
      main: "#000000", // Set the primary color to black
    },
    secondary: {
      main: "#FFFFFF", // Set the secondary color to white
    },
    success: {
      main: "#4caf50", // Set the success color to green
    },
  },
});

function Schedule() {
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [isEmojiPickerOpen, setEmojiPickerOpen] = useState(false);

  const [selectedIcons, setSelectedIcons] = useState([]);
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const [connectedPlatforms, setConnectedPlatforms] = useState([]);
  const [selectedTime, setSelectedTime] = useState(null);
  const [caption, setCaption] = useState("");
  const [uploadedPhotoKey, setUploadedPhotoKey] = useState(null);

  const handleCaptionChange = (event) => {
    setCaption(event.target.value);
  };

  const handleDateTimeChange = (newTime) => {
    console.log(newTime);
    console.log(caption);
    setSelectedTime(newTime);
  };

  const handleSidebarToggle = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  const handleEmojiToggle = () => {
    setEmojiPickerOpen(!isEmojiPickerOpen);
  };

  const handleEmojiSelect = (emoji) => {
    // Handle emoji selection
    console.log("Selected emoji:", emoji);
    setEmojiPickerOpen(false); // Close emoji picker after selection
  };

  const handleIconToggle = (icon) => {
    console.log(selectedIcons);
    if (selectedIcons.includes(icon)) {
      setSelectedIcons(
        selectedIcons.filter((selectedIcon) => selectedIcon !== icon)
      );
    } else {
      setSelectedIcons([...selectedIcons, icon]);
    }
  };

  const isIconSelected = (icon) => selectedIcons.includes(icon);

  // const handlePhotoSelect = (event) => {
  //   const file = event.target.files[0];
  //   if (file) {
  //     const reader = new FileReader();
  //     reader.onload = (e) => {
  //       setSelectedPhoto(e.target.result);
  //     };
  //     reader.readAsDataURL(file);
  //   }
  // };

  const handlePhotoSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setSelectedPhoto(e.target.result); // Store the data URL in selectedPhoto state
      };
      reader.readAsDataURL(file);

      const formData = new FormData();
      formData.append("photos", file); // Use 'photos' as the field name

      axios
        .post("https://api.thenarad.in/upload", formData)
        .then((response) => {
          // Assuming response.data is an array of uploaded files, get the key of the first file
          if (response.data.length > 0) {
            setUploadedPhotoKey(response.data[0].key); // Store the key in uploadedPhotoKey state
          } else {
            console.error("No files uploaded");
          }
        })
        .catch((error) => {
          console.error("Error uploading photo:", error);
        });
    }
  };

  useEffect(() => {
    // Fetch email from local storage
    const email = localStorage.getItem("email");

    // Make request to backend endpoint to get all connected platforms
    axios
      .get(`https://api.thenarad.in/getallconnectedplatform/${email}`)
      .then((response) => {
        console.log("Connected Platforms:", response.data);
        // Set the response data to state
        setConnectedPlatforms(response.data.connectedPlatforms);
      })
      .catch((error) => {
        console.error("Error fetching connected platforms:", error);
      });
  }, []);

  const handleschedule = () => {
    const email = localStorage.getItem("email");
    const requestBody = {
      email: email,
      postdescription: caption,
      Photo: uploadedPhotoKey,
      scheduledtime: selectedTime,
      platform: selectedIcons,
    };

    // Send POST request to '/addschedulerjob' route
    axios
      .post("https://api.thenarad.in/addschedulerjob", requestBody)
      .then((response) => {
        toast.success("Post scheduled successfully", {
          theme: "dark"
        })
      })
      .catch((error) => {
        console.error("Error adding schedule job:", error);
      });
  };

  
  const handlehashtags = () => {
    toast.info("Generating....",{
      theme: "dark"
    });
 
    const body = {
      prompt: caption,
      length: "short",
      tone: "",
      type: "",
      hashtags: "30",
      entity: "person",
    };

    axios
      .post("https://api.thenarad.in/generatehashtags", body)
      .then((response) => {
        console.log(response.data);
        const tofilter = response.data.data[0].text;
        const stringWithoutQuotes = tofilter.replace(/^"|"$/g, "");
        setCaption(stringWithoutQuotes);
        toast.success("Generated successfully", {
          theme: "dark"
        })
      })
      .catch((error) => {});
  };

  return (
    <ThemeProvider theme={theme}>
      <div>
        <CssBaseline />
        <AppBar position="fixed">
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open sidebar"
              onClick={handleSidebarToggle}
              sx={{ mr: 2 }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap>
              SOCIAL MEDIA SCHEDULER
            </Typography>
          </Toolbar>
        </AppBar>
        <Sidebar isOpen={isSidebarOpen} onClose={handleSidebarToggle} />
        <Container component="main" sx={{ flexGrow: 1, p: 3, marginTop: 20 }}>
          <Paper elevation={3} sx={{ padding: 3, borderRadius: 8 }}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12}>
                <TextField
                  id="compose-text"
                  label="What topic should the caption focus on?"
                  multiline
                  rows={4}
                  fullWidth
                  value={caption}
                  onChange={handleCaptionChange}
                  InputProps={{
                    style: { borderRadius: 8 },
                    startAdornment: selectedPhoto && (
                      <img
                        src={selectedPhoto}
                        alt="Selected"
                        style={{
                          borderRadius: 8,
                          width: "auto",
                          height: "auto",
                          maxWidth: 100,
                          maxHeight: 100,
                          marginRight: 8,
                        }}
                      />
                    ),
                  }}
                />
              </Grid>

              <Grid item>
                <input
                  accept="image/*"
                  id="icon-button-photo"
                  type="file"
                  style={{ display: "none" }}
                  onChange={handlePhotoSelect}
                />
                <label htmlFor="icon-button-photo">
                  <IconButton color="primary" component="span">
                    <InsertPhotoIcon />
                  </IconButton>
                </label>
              </Grid>
              <Grid item>
                <IconButton color="primary">
                  <InsertVideoIcon />
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton color="primary" onClick={handleEmojiToggle}>
                  <InsertEmoticonIcon />
                </IconButton>
                {isEmojiPickerOpen && (
                  <Paper
                    elevation={3}
                    sx={{
                      position: "absolute",
                      zIndex: 1,
                      borderRadius: 8,
                      mt: 1,
                    }}
                  >
                    <EmojiPicker onEmojiClick={handleEmojiSelect} />
                  </Paper>
                )}
              </Grid>
              <Grid item xs={12}>
              <Button
                    variant="contained"
                    color="primary"
                    onClick={handlehashtags}
                  >
                    Generate
                  </Button>
              </Grid>
              <br />
              <Grid item xs={12}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    label="Schedule Time"
                    value={selectedTime}
                    onChange={handleDateTimeChange}
                  />
                </LocalizationProvider>
              </Grid>
              <br /> 
              <Grid item>
                {connectedPlatforms.includes("Instagram") && (
                  <IconButton
                    color="primary"
                    onClick={handleIconToggle.bind(null, "Instagram")}
                  >
                    <InstagramIcon
                      sx={{
                        color: isIconSelected("Instagram")
                          ? "success.main"
                          : "inherit",
                      }}
                    />
                  </IconButton>
                )}
              </Grid>
              <Grid item>
                {connectedPlatforms.includes("Insta Threads") && (
                  <IconButton
                    color="primary"
                    onClick={handleIconToggle.bind(null, "Insta Threads")}
                  >
                    <GestureIcon
                      sx={{
                        color: isIconSelected("Insta Threads")
                          ? "success.main"
                          : "inherit",
                      }}
                    />
                  </IconButton>
                )}
              </Grid>
              <Grid item>
                {connectedPlatforms.includes("Facebook") && (
                  <IconButton
                    color="primary"
                    onClick={handleIconToggle.bind(null, "Facebook")}
                  >
                    <FacebookIcon
                      sx={{
                        color: isIconSelected("Facebook")
                          ? "success.main"
                          : "inherit",
                      }}
                    />
                  </IconButton> 
                )}
              </Grid>
              <Grid item>
                {connectedPlatforms.includes("Twitter") && (
                  <IconButton
                    color="primary"
                    onClick={handleIconToggle.bind(null, "X")}
                  >
                    <XIcon
                      sx={{
                        color: isIconSelected("X") ? "success.main" : "inherit",
                      }}
                    />
                  </IconButton>
                )}
              </Grid>
              {connectedPlatforms.length === 0 && (
                <Grid item xs={12}>
                  <Typography sx={{ color: "red", fontWeight: "bold" }}>
                    Please connect any social media account first
                  </Typography>
                </Grid>
              )}
              <br />
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleschedule}
                >
                  Schedule
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Container>
        <ToastContainer />
      </div>
    </ThemeProvider>
  );
}

export default Schedule;
