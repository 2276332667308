import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import initFacebookSDK from "./initFacebookSDK";

const renderApp = () => {
  const root = ReactDOM.createRoot(document.getElementById('root'));
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
};

initFacebookSDK().then(() => {
  renderApp();
});