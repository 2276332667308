import React, { useState } from "react";
import {
  CssBaseline,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Container,
  createTheme,
  ThemeProvider,
  Grid,
  Button,
  TextField,
  Paper,
  Autocomplete,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import Sidebar from "./Sidebar";
import axios from "axios"; // Import axios for making HTTP requests
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'

const theme = createTheme({
  palette: {
    primary: {
      main: "#000000", // Set the primary color to black
    },
    secondary: {
      main: "#FFFFFF", // Set the secondary color to white
    },
    success: {
      main: "#4caf50", // Set the success color to green
    },
  },
});

const privacyOptions = ["public", "unlisted", "private"];

function Youtube() {
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [selectedPrivacy, setSelectedPrivacy] = useState("public");
  const [title, setTitle] = useState("");
  const [tags, setTags] = useState([]);
  const [description, setDescription] = useState("");
  const [videoFile, setVideoFile] = useState(null);
  // const [uploadedVideoKey, setUploadedVideoKey] = useState(null); 
  const [selectedTime, setSelectedTime] = useState(null);

  const handleSidebarToggle = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  const handlePrivacyChange = (event) => {
    setSelectedPrivacy(event.target.value);
  };

  const handleDateTimeChange = (newTime) => {
    setSelectedTime(newTime);
  };

  const handleUploadVideo = async () => {
    if (!videoFile) {
      alert("Please select a video file to upload.");
      return;
    }

    const formData = new FormData();
    formData.append("photos", videoFile);

    try {
      const response = await axios.post("https://api.thenarad.in/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      // Extract the key from the response and set it to the state variable
      const { key } = response.data[0];
      const email = localStorage.getItem("email");
      const requestBody = {
      email: email,
      Title: title,
      Hashtags: tags, 
      postdescription: description,
      Photo: key,
      Privacy: selectedPrivacy,
      scheduledtime: selectedTime,
      platform: ['Youtube']
    };
      axios
      .post("https://api.thenarad.in/addschedulerjobyt", requestBody)
      .then((response) => {
        toast.success("Youtube Video scheduled successfully", {
          theme: "dark"
        })
      })
      .catch((error) => {
        console.error("Error adding schedule job:", error);
      });

    } catch (error) {
      console.error("Error uploading video:", error);
      alert("Error uploading video. Please try again later.");
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <div>
        <CssBaseline />
        <AppBar position="fixed">
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open sidebar"
              onClick={handleSidebarToggle}
              sx={{ mr: 2 }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap>
              YouTube Video Upload
            </Typography>
          </Toolbar>
        </AppBar>
        <Sidebar isOpen={isSidebarOpen} onClose={handleSidebarToggle} />
        <Container component="main" sx={{ flexGrow: 1, p: 3, marginTop: 20 }}>
          <Paper elevation={3} sx={{ padding: 3, borderRadius: 8 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  multiple
                  options={[]}
                  freeSolo
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      label="Tags"
                      placeholder="Add tags"
                    />
                  )}
                  value={tags}
                  onChange={(event, newValue) => {
                    setTags(newValue);
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  label="Description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <input
                  type="file"
                  accept="video/*"
                  onChange={(e) => setVideoFile(e.target.files[0])}
                />
              </Grid>
              <Grid item xs={12}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    label="Schedule Time"
                    value={selectedTime}
                    onChange={handleDateTimeChange}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Select
                    labelId="privacy-label"
                    value={selectedPrivacy}
                    onChange={handlePrivacyChange}
                  >
                    {privacyOptions.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleUploadVideo}
                >
                  Upload Video
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Container>
        <ToastContainer />
      </div>
    </ThemeProvider>
  );
}

export default Youtube;
