import React, { useState } from 'react';
import {
  CssBaseline,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Container,
  createTheme,
  ThemeProvider,
  Paper,
  Avatar,
  Button,
  Grid,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Sidebar from './Sidebar';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

const theme = createTheme({
  palette: {
    primary: {
      main: '#000000',
    },
    secondary: {
      main: '#FFFFFF',
    },
  },
});

function Settings() {
  const [isSidebarOpen, setSidebarOpen] = useState(false);


  const handleSidebarToggle = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  const handleLogout = () => {
    try {
      // Remove token, expiresIn, and email from localStorage
      localStorage.removeItem('token');
      localStorage.removeItem('expiresIn');
      localStorage.removeItem('email');
      // Force navigation to the home page
      window.location.href = '/';
    } catch (error) {
      console.error('Error logging out:', error);
      // If an error occurs during logout, you can handle it here
      // For example, display an error message to the user
    }
  };
  

  // Fetch email from localStorage or use a default value if not found
  const userEmail = localStorage.getItem('email') || 'example@example.com';

  return (
    <ThemeProvider theme={theme}>
      <div>
        <CssBaseline />
        <AppBar position="fixed">
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open sidebar"
              onClick={handleSidebarToggle}
              sx={{ mr: 2 }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap>
              SOCIAL MEDIA SCHEDULER
            </Typography>
          </Toolbar>
        </AppBar>
        <Sidebar isOpen={isSidebarOpen} onClose={handleSidebarToggle} />
        <Container
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh', // Set container height to 100% of viewport height
          }}
        >
          <Paper sx={{ p: 3, display: 'flex', flexDirection: 'column', alignItems: 'center', boxShadow: 3, borderRadius: 8 }}>
            <Avatar sx={{ width: 100, height: 100, mb: 2 }}>
              <AccountCircleIcon fontSize="large" />
            </Avatar>
            <Typography variant="h6" gutterBottom>
              John Doe {/* Replace with user's name */}
            </Typography>
            <Grid container spacing={2}>
              <Grid item>
                <MailOutlineIcon />
              </Grid>
              <Grid item>
                <Typography variant="body1">
                  {userEmail} {/* Display fetched email */}
                </Typography>
              </Grid>
            </Grid>
            <Button
              variant="contained"
              color="primary"
              startIcon={<ExitToAppIcon />}
              sx={{ mt: 3 }}
              onClick={handleLogout} 
            >
              Logout
            </Button>
          </Paper>
        </Container>
      </div>
    </ThemeProvider>
  );
}

export default Settings;
