import React, { useState, useEffect } from "react";
import {
  CssBaseline,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Container,
  createTheme,
  ThemeProvider,
  Button,
  TextField,
  Paper,
  InputAdornment,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import Sidebar from "./Sidebar";
import AttachMoneyIcon from "@mui/icons-material/CurrencyRupee";
import axios from "axios";

const theme = createTheme({
  palette: {
    primary: {
      main: "#000000", // Set the primary color to black
    },
    secondary: {
      main: "#FFFFFF", // Set the secondary color to white
    },
  },
});

function Wallet() {
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [amount, setAmount] = useState("");
  const [warning, setWarning] = useState("");
  const [dashboardData, setDashboardData] = useState(null);

  const handleSidebarToggle = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  const handleAmountChange = (event) => {
    const value = event.target.value;
    setAmount(value);

    if (value < 50) {
      setWarning("Amount should be at least ₹50");
    } else if (value > 49999) {
      setWarning("Amount cannot exceed ₹49,999");
    } else {
      setWarning("");
    }
  };

  useEffect(() => {
    // Fetch email from local storage
    const email = localStorage.getItem("email");

    // Make request to backend endpoint to get dashboard data
    axios
      .get(`https://api.thenarad.in/getdashboarddata/${email}`)
      .then((response) => {
        console.log("Dashboard Data:", response.data);
        // Set the response data to state
        setDashboardData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching dashboard data:", error);
      });
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <div>
        <CssBaseline />
        <AppBar position="fixed">
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open sidebar"
              onClick={handleSidebarToggle}
              sx={{ mr: 2 }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap>
              SOCIAL MEDIA SCHEDULER
            </Typography>
          </Toolbar>
        </AppBar>
        <Sidebar isOpen={isSidebarOpen} onClose={handleSidebarToggle} />
        <Container component="main" sx={{ flexGrow: 1, p: 3, marginTop: 20 }}>
          <Paper elevation={3} sx={{ padding: 3, borderRadius: 8 }}>
            <Typography variant="h5" gutterBottom>
              <strong>Wallet</strong>
            </Typography>
            <Typography variant="body1" gutterBottom>
              Add money to your wallet:
            </Typography>
            <TextField
              id="wallet-amount"
              label="Amount (₹)"
              type="number"
              value={amount}
              onChange={handleAmountChange}
              error={!!warning}
              helperText={warning}
              InputProps={{
                inputProps: { min: 50, max: 49999 },
                startAdornment: (
                  <InputAdornment position="start">
                    <AttachMoneyIcon />
                  </InputAdornment>
                ),
              }}
              fullWidth
            />
            <Typography variant="body2" sx={{ mt: 1 }}>
              <strong>Current Credits:</strong>{" "}
              <span style={{ color: theme.palette.success.main }}>
                ₹{dashboardData ? dashboardData.credits : "Loading..."}
              </span>
            </Typography>
            <Button
              variant="contained"
              color="primary"
              sx={{ mt: 2 }}
              disabled={!!warning || !amount}
            >
              Add Money
            </Button>
          </Paper>
        </Container>
      </div>
    </ThemeProvider>
  );
}

export default Wallet;
