import React, { useState, useEffect } from "react";
import {
  CssBaseline,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Container,
  createTheme,
  ThemeProvider,
  Paper,
  Grid,
  Button,
  // ButtonGroup,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import Sidebar from "./Sidebar";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import EmailIcon from "@mui/icons-material/Email";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import InstagramIcon from "@mui/icons-material/Instagram";
// import GestureIcon from '@mui/icons-material/Gesture';
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";
import XIcon from "@mui/icons-material/X";
import ThreadsModal from "./ThreadsModal";
import axios from "axios";


const theme = createTheme({
  palette: {
    primary: {
      main: "#000000",
    },
    secondary: {
      main: "#FFFFFF",
    },
  },
});

function Home() {
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [isThreadsModalOpen, setThreadsModalOpen] = useState(false);
  // const [instagramCredentials, setInstagramCredentials] = useState({ username: '', password: '' });
  const [connectedPlatforms, setConnectedPlatforms] = useState([]);
  const [dashboardData, setDashboardData] = useState(null);
  // const [facebookUserAccessToken, setFacebookUserAccessToken] = useState("");


  // useEffect(() => {
  //   window.FB.getLoginStatus((response) => {
  //     setFacebookUserAccessToken(response.authResponse?.accessToken);
  //   });
  // }, []);

  const updateIGAccessTokenToDB = (access_token) => {
    const email = localStorage.getItem("email"); // Get email from local storage
    axios
      .post(`https://api.thenarad.in/connectinsta/${email}`, {
        token: access_token
      })
      .then((response) => {
        window.location.reload();
      })
      .catch((error) => {
        console.error("Error connecting Instagram credentials:", error);
      });
  };
  

  const handleInstagramConnect = () => {
    window.FB.login(
      (response) => {
        updateIGAccessTokenToDB(response.authResponse?.accessToken);
      },
      {
        // Scopes that allow us to publish content to Instagram
        scope: "instagram_basic,pages_show_list",
      }
    );
  };


  const handleSidebarToggle = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  // const handleInstagramConnect = () => {
  //   setInstagramModalOpen(true);
  // };



  // const handleThreadsConnect = () => {
  //   setThreadsModalOpen(true);
  // };

  const handleCloseThreadsModal = () => {
    setThreadsModalOpen(false);
  };



  const handleSubmitThreadsCredentials = (username, password) => {
    const email = localStorage.getItem("email"); // Get email from local storage
    axios
      .post(`https://api.thenarad.in/connectthreads/${email}`, {
        username,
        password,
      })
      .then((response) => {
        window.location.reload();
      })
      .catch((error) => {
        console.error("Error connecting Instagram credentials:", error);
      });
  };

  useEffect(() => {
    // Fetch email from local storage
    const email = localStorage.getItem("email");

    // Make request to backend endpoint to get all connected platforms
    axios
      .get(`https://api.thenarad.in/getallconnectedplatform/${email}`)
      .then((response) => {
        console.log("Connected Platforms:", response.data);
        // Set the response data to state
        setConnectedPlatforms(response.data.connectedPlatforms);
      })
      .catch((error) => {
        console.error("Error fetching connected platforms:", error);
      });

    // Make request to backend endpoint to get dashboard data
    axios
      .get(`https://api.thenarad.in/getdashboarddata/${email}`)
      .then((response) => {
        console.log("Dashboard Data:", response.data);
        // Set the response data to state
        setDashboardData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching dashboard data:", error);
      });
  }, []);

  const handleYoutubeLogin = async () => {
    try {
      const response = await axios.get('https://api.thenarad.in/auth/youtube');
      console.log(response.data.authUrl);
      window.location.href = response.data.authUrl;
    } catch (error) {
      console.error('Error logging in with YouTube:', error);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <div>
        <CssBaseline />
        <AppBar position="fixed">
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open sidebar"
              onClick={handleSidebarToggle}
              sx={{ mr: 2 }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap>
              SOCIAL MEDIA SCHEDULER
            </Typography>
          </Toolbar>
        </AppBar>
        <Sidebar isOpen={isSidebarOpen} onClose={handleSidebarToggle} />
        <Container component="main" sx={{ flexGrow: 1, marginTop: 20 }}>
          <Grid container spacing={3}>
            {/* 1st Box: Connected Accounts */}
            <Grid item xs={12} sm={6} md={4}>
              <Paper
                sx={{
                  p: 2,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  boxShadow: 3,
                }}
              >
                <AccountCircleIcon fontSize="large" />
                <Typography variant="h6" gutterBottom>
                  Connected Accounts
                </Typography>
                <Typography variant="h4">
                  {dashboardData && dashboardData.connectedAccounts
                    ? Object.keys(dashboardData.connectedAccounts).reduce(
                        (acc, key) => {
                          return (
                            acc +
                            (dashboardData.connectedAccounts[key] > 0 ? 1 : 0)
                          );
                        },
                        0
                      )
                    : "Loading..."}
                </Typography>
              </Paper>
            </Grid>

            {/* 2nd Box: Number of Posts */}
            <Grid item xs={12} sm={6} md={4}>
              <Paper
                sx={{
                  p: 2,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  boxShadow: 3,
                }}
              >
                <EmailIcon fontSize="large" />
                <Typography variant="h6" gutterBottom>
                  Number of Posts
                </Typography>
                <Typography variant="h4">
                  {dashboardData ? dashboardData.numberOfPosts : "Loading..."}
                </Typography>
              </Paper>
            </Grid>

            {/* 3rd Box: Credits */}
            <Grid item xs={12} sm={6} md={4}>
              <Paper
                sx={{
                  p: 2,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  boxShadow: 3,
                }}
              >
                <CreditCardIcon fontSize="large" />
                <Typography variant="h6" gutterBottom>
                  Credits
                </Typography>
                <Typography variant="h4">
                  {dashboardData ? dashboardData.credits : "Loading..."}
                </Typography>
              </Paper>
            </Grid>

            {/* 4th Box: Connect Buttons */}
            {/* 4th Box: Connect Buttons */}
            <Grid item xs={12}>
              <Paper
                sx={{
                  p: 3,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  boxShadow: 3,
                }}
              >
                <Button
                  sx={{
                    width: "100%", // Set a fixed width for all buttons
                    background: connectedPlatforms.includes("Instagram")
                      ? "linear-gradient(45deg, #4caf50, #4caf50)"
                      : "linear-gradient(45deg, #f06292, #ff8e53)",
                    color: "white",
                    marginBottom: 2,
                    borderRadius: 2,
                    border: 0,
                  }}
                  disabled={connectedPlatforms.includes("Instagram")}
                  onClick={handleInstagramConnect}
                >
                  <InstagramIcon fontSize="large" />
                  {connectedPlatforms.includes("Instagram")
                    ? "Connected"
                    : "Connect Instagram"}
                </Button>
                <Button
                  sx={{
                    width: "100%", // Set a fixed width for all buttons
                    background: connectedPlatforms.includes("Facebook")
                      ? "#4caf50"
                      : "linear-gradient(45deg, #1877f2, #1877f2)",
                    color: "white",
                    marginBottom: 2,
                  }}
                  disabled={connectedPlatforms.includes("Facebook")}
                >
                  <FacebookIcon fontSize="large" />
                  {connectedPlatforms.includes("Facebook")
                    ? "Connected"
                    : "Connect Facebook"}
                </Button>
                <Button
                  sx={{
                    width: "100%", // Set a fixed width for all buttons
                    background: connectedPlatforms.includes("YouTube")
                      ? "#4caf50"
                      : "linear-gradient(45deg, #c4302b, #c4302b)",
                    color: "white",
                    marginBottom: 2,
                  }}
                  disabled={connectedPlatforms.includes("YouTube")}
                  onClick={handleYoutubeLogin}
                >
                  <YouTubeIcon fontSize="large" />

                  {connectedPlatforms.includes("YouTube")
                    ? "Connected"
                    : "Connect YouTube"}
                    
                </Button>
                <Button
                  sx={{
                    width: "100%", // Set a fixed width for all buttons
                    background: connectedPlatforms.includes("Twitter")
                      ? "#4caf50"
                      : "linear-gradient(45deg, #000000, #000000)",
                    color: "white",
                    marginBottom: 2,
                  }}
                  disabled={connectedPlatforms.includes("Twitter")}
                >
                  <XIcon fontSize="large" />

                  {connectedPlatforms.includes("Twitter")
                    ? "Connected"
                    : "Connect Twitter"}
                </Button>
                {/* <Button
                  sx={{
                    width: "100%", // Set a fixed width for all buttons
                    background: connectedPlatforms.includes("Insta Threads")
                      ? "#4caf50"
                      : "linear-gradient(45deg, #000000, #000000)",
                    color: "white",
                  }}
                  disabled={connectedPlatforms.includes("Insta Threads")}
                  onClick={handleThreadsConnect}
                >
                  <GestureIcon fontSize="large" />
                  {connectedPlatforms.includes("Insta Threads")
                    ? "Connected to Threads"
                    : "Connect Insta Threads"}
                </Button> */}
              </Paper>
            </Grid>
          </Grid>
        </Container>
    
        <ThreadsModal
          open={isThreadsModalOpen}
          onClose={handleCloseThreadsModal}
          onSubmit={handleSubmitThreadsCredentials}
        />
      </div>
    </ThemeProvider>
  );
}

export default Home;
