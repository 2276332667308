import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Home from './components/Home';
import Login from './components/Login';
import Schedule from './components/Schedule';
import Settings from './components/Settings';
import Wallet from './components/Wallet';
import Posts from './components/Posts';
import Youtube from './components/Youtube';
import Policies from './components/Policies ';
import axios from 'axios';

const PrivateRoute = ({ element, authenticated }) => {
  return authenticated ? element : <Navigate to="/" />;
};

const App = () => {
  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('token');

    if (token) {
      const config = {
        headers: {
          Authorization: `${token}`,
        },
      };

      axios.get('https://api.thenarad.in/protected-route', config)
        .then(response => {
          if (response.status === 200) {
            return response.data;
          } else {
            throw new Error(response.statusText);
          }
        })
        .then(data => {
          console.log(data.message);
          if (data.message === 'Protected route accessed successfully') {
            setAuthenticated(true);
          }
        })
        .catch(error => {
          console.error('Error fetching /protected-route:', error);
        });
    }
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/" element={authenticated ? <Home/> : <Login />} />
        <Route path="/Policies" element={<Policies />} />
        <Route path="/login" element={<Login />} />
        <Route
          path="/schedule"
          element={<PrivateRoute element={<Schedule />} authenticated={authenticated} />}
        />
        <Route
          path="/wallet"
          element={<PrivateRoute element={<Wallet />} authenticated={authenticated} />}
        /> 
         <Route
          path="/settings"
          element={<PrivateRoute element={<Settings />} authenticated={authenticated} />}
        />
            <Route 
          path="/history"
          element={<PrivateRoute element={<Posts />} authenticated={authenticated} />}
        />
          <Route 
          path="/ytstuff"
          element={<PrivateRoute element={<Youtube />} authenticated={authenticated} />}
        />
      </Routes>
    </Router>
  );
};

export default App;
