import React, { useState, useEffect } from 'react';
import { Typography, TextField, Checkbox, Button, Box, Alert,CircularProgress  } from '@mui/material';
import { FormControlLabel } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import axios from 'axios';
// import { useNavigate } from 'react-router-dom';

const Login = () => {
  // const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);
  const [rloading, setrLoading] = useState(false);

  const handleLogin = async () => {
    try {
      setLoading(true);

      const response = await axios.post('https://api.thenarad.in/login', {
        email,
        password,
        rememberMe,
      });

      const { token, expiresIn } = response.data;

      localStorage.setItem('token', token);
      localStorage.setItem('expiresIn', expiresIn);
      localStorage.setItem('email', email);

      setError('');
      setSuccess('Login successful!');
      setLoading(false);
      if(success !== '') {
        window.location.reload();
      }
    } catch (error) {
      handleError(error);
    }
  };

  const handleRegister = async () => {
    try {
      setLoading(true);

      const response = await axios.post('https://api.thenarad.in/register', {
        email,
        password,
        rememberMe,
      });

      const { token, expiresIn } = response.data;

      localStorage.setItem('token', token);
      localStorage.setItem('expiresIn', expiresIn);
      localStorage.setItem('email', email);

      setError('');
      setSuccess('Registration successful!');
      setrLoading(false);
    } catch (error) {
      handleError(error);
    }
  };

  const handleError = (error) => {
    setLoading(false);

    if (error.response && error.response.data) {
      setError(error.response.data.message);
    } else {
      setError('An unexpected error occurred.');
    }
  };
  useEffect(() => {
    if(success !== ''){
      window.location.reload();
    }
  }, [success]);

  return (
    <>
      <div>
        <style jsx global>{`
          body {
            margin: 0;
            padding: 0;
          }

          @media (max-width: 767px) {
            #leftBox {
              display: none;
            }
          }
        `}</style>
      </div>
      <Box
        display="flex"
        flexDirection="row"
        height="100vh"
        overflow="hidden" // Prevents scrollbars
        margin="0"  // Reset margin for the body
      >
        {/* Left side - Unsplash image */}
        <Box
          id="leftBox"
          flex="1"
          height="100%" // Set a specific height for the left box
          backgroundSize="cover"
          backgroundPosition="center"
          style={{
            backgroundImage: `url("https://images.unsplash.com/photo-1705154580249-55990fe3a8fb?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8fHx8fHx8MTcwNjI5NzY0OA&ixlib=rb-4.0.3&q=80&w=1080")`,
            margin: 0,
            padding: 0,
          }}
        />
        {/* Right side - Login component */}
        <Box
          flex="1"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          padding="2rem"
          margin="0"
        >
          <div style={{ maxWidth: '400px', margin: 0 }}>
            {error && (
              <Alert severity="error" sx={{ marginBottom: '1rem' }}>
                {error}
              </Alert>
            )}
            {success && (
              <Alert severity="success" sx={{ marginBottom: '1rem' }}>
                {success}
              </Alert>
            )}
            <Typography variant="h4" gutterBottom>
              Log in / Register
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              Welcome Back! Please enter your details
            </Typography>
            <TextField
              label="Email"
              fullWidth
              sx={{ marginBottom: '1rem' }}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              label="Password"
              type="password"
              fullWidth
              sx={{ marginBottom: '1rem' }}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <FormControlLabel
              control={
                <Checkbox
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                  checked={rememberMe}
                  onChange={() => setRememberMe(!rememberMe)}
                />
              }
              label="Remember me for 30 days"
            />
            <Button
              variant="contained"
              fullWidth
              sx={{
                backgroundColor: 'black',
                color: 'white',
                marginTop: '1rem',
                '&:hover': {
                  backgroundColor: 'black', // Remove hover color
                },
              }}
              onClick={handleLogin}
              disabled={loading}
            >
              {loading ? <CircularProgress size={20} color="inherit" /> : 'Log in'}
            </Button>
            <Button
              variant="outlined"
              fullWidth
              sx={{
                color: 'black',
                borderColor: 'black',
                marginTop: '0.5rem',
                '&:hover': {
                  borderColor: 'black', // Remove hover color
                },
              }}
              onClick={handleRegister}
              disabled={loading}
            >
                {rloading ? <CircularProgress size={20} color="inherit" /> : 'Register'}
            </Button>
            <Typography variant="body2" style={{ marginTop: '1rem', color: 'black' }}>
  By logging in or registering, you agree to our <a href="/Policies" style={{ color: 'black' }}>Policies</a>.
</Typography>


          </div>
        </Box>
      </Box>
    </>
  );
};

export default Login;
